#menu-sidebar-mobile-container {
  padding-top: 24px;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 16px;

  color: rgba(16, 70, 70, 1);
  font-family: "Gotham-Medium";
  letter-spacing: 0.18em;

  background-color: white;
  z-index: 999;
  position: sticky;
  top: -8px;
}

#menu-sidebar-mobile-container h2 {
  font-size: 20px;
  line-height: 130%;
  margin-bottom: 6px;
  font-weight: bold;
}

/* Sections */
#menu-sidebar-mobile-sections {
  display: flex;
  overflow: auto;
  white-space: nowrap;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#menu-sidebar-mobile-sections::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#menu-sidebar-mobile-sections {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu-sidebar-mobile-section-container {
  padding-top: 10px;
  margin-right: 12%;
}

.menu-sidebar-mobile-section-container a {
  color: rgba(16, 70, 70, 1);
  text-decoration: none;
}

.menu-sidebar-mobile-section-container h3 {
  font-size: 14px;
  line-height: 110%;
  font-weight: bold;

  margin: 0;
}

/* Green Line */
.menu-sidebar-mobile-green-line {
  border-bottom: 3px solid rgba(162, 212, 169, 1);
  padding-bottom: 2px;
}
