/* Carousel Container */
#menu-carousel-container {
  margin-top: 36px;
  margin-bottom: 82px;
}

/* Yellow Box */
#most-celebrated {
  background-color: rgba(255, 247, 224, 1);
  display: flex;
  height: 292px;
  color: rgba(16, 70, 70, 1);
}

/* Carousel Title */
#menu-carousel-header {
  margin-top: 56px;
  margin-left: 8%;
}

/* Carousel */
#menu-carousel {
  margin-top: -160px;
}

.menu-carousel-item {
  display: flex;
}

.menu-carousel-item img {
  width: 200px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
  justify-content: center;
}

/* Prev, next icons */
.carousel-control-prev-icon {
  width: 16px;
  height: 30px;
  background-image: url("/images/menu/carousel/carousel_left.svg");
}

.carousel-control-next-icon {
  width: 16px;
  height: 30px;
  background-image: url("/images/menu/carousel/carousel_right.svg");
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #menu-carousel-container {
    margin-top: 0;
  }

  #most-celebrated {
    height: 216px;
    scroll-margin-top: 90px;
  }

  #menu-carousel {
    margin-top: -110px;
  }

  #menu-carousel-header {
    margin-top: 6%;
    margin-left: 4%;
  }

  .menu-carousel-item img {
    width: 160px;
  }
}
