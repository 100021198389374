/* Container */
#home-featured-image-section {
  position: relative;
}

/* Home Image */
#home-featured-image {
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
  margin-top: -39px;
  position: relative;
  z-index: -1;
}

#home-featured-image-mobile {
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
  margin-top: -6%;
  display: none;
  position: relative;
  z-index: -1;
}

/* Text */
#home-image-text-container {
  position: absolute;
  top: 25%;
  left: 15%;
  color: white;
}

#home-image-text-container h1 {
  font-size: 84px;
}

#home-image-text-container h2 {
  font-family: "Gotham-Medium";
  letter-spacing: 0.2em;
  font-size: 20px;

  margin-top: 10%;
  display: inline-block;

  padding-bottom: 2%;
  border-bottom: 3px solid white;
}

#home-image-text-container a {
  text-decoration: none;
  color: inherit;
}

/* Hover */
#home-image-text-container h2:hover {
  color: gray;
  border-bottom: 3px solid gray;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #home-image-text-container h1 {
    font-size: 36px;
  }
}

/* Responsive (Mobile) */
@media only screen and (max-width: 512px) {
  #home-featured-image {
    display: none;
  }
  #home-featured-image-mobile {
    display: flex;
  }
  #home-image-text-container {
    top: 8%;
    left: 15%;
  }
}
