#about-text-blue-wrapper {
  margin-left: 10%;
  margin-top: -30%;
  width: 50%;
  margin-right: 5%;
  background-color: rgba(228, 247, 245, 1);
  z-index: 1;
  display: inline-block;
}

.about-text-container {
  display: inline-block;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 8%;
  color: rgba(16, 70, 70, 1);
}

#about-signature {
  margin-top: 20%;
  margin-bottom: 82px;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  /* Design for mobile */
  #about-text-blue-wrapper {
    margin-left: 5%;
    margin-top: 0;
    width: 90%;
    margin-right: 5%;
  }
}
