@font-face {
  font-family: "Gotham-Book";
  src: url("fonts/Gotham/Unknown/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("fonts/Gotham/Hoefler-Type/Gotham-Medium.otf") format("opentype");
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "Bitter";
  font-weight: 700;
  letter-spacing: 1px;
}

p {
  font-family: "Gotham-Book";
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 23.4px;
  margin: 0;
}

/* App */
#app-container {
  display: flex;
  flex-direction: column;
}
