#home-delivery-container {
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  margin-right: 8%;
  width: 84%;
  margin-bottom: 8%;
  justify-content: center;
  align-items: center;
}

.home-delivery-horizontal-container {
  display: flex;
  justify-content: center;
}

.home-delivery-title-container {
  margin-bottom: 36px;
}

#home-delivery-text {
  text-align: center;
  color: rgba(16, 70, 70, 1);
}

#home-delivery-container a {
  margin-top: 3%;
  margin-left: 9%;
  margin-right: 9%;
}

/* Mobile */
#home-delivery-section-mobile {
  display: none;
  flex-direction: column;
  margin-top: 5%;
}

#home-delivery-section-mobile-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home-delivery-section-mobile-top h1 {
  margin-top: 10%;
  margin-bottom: 56px;
}

#home-delivery-section-mobile a {
  margin-bottom: 36px;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #home-delivery-section-normal {
    display: none;
  }
  #home-delivery-section-mobile {
    display: flex;
  }
}
