/* Styling for entire nav bar */
.navbar {
  padding: 0;
  margin-left: 4%;
  margin-right: 4%;
}

.navbar-nav {
  margin-bottom: 40px;
}

/* Brand, Link, Button specific styles */
#navbar-logo-img {
  width: 301px;
  height: auto;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(16, 70, 70, 1);
  white-space: nowrap;
  font-family: "Gotham-Medium";
  font-size: 0.9em;
  letter-spacing: 0.19em;
}

.navbar-nav:hover .nav-link:hover {
  color: rgba(16, 70, 70, 1);
}

.leaf-link-container {
  display: flex;
  margin-right: 1.5rem;
}

.leaf-link-container img {
  visibility: hidden;
  width: 22px;
  height: auto;
}

.leaf-link-container:hover img {
  visibility: visible;
}

#order-now-dropdown {
  white-space: nowrap;
  font-family: "Gotham-Medium";
  font-size: 0.9em;
  letter-spacing: 0.19em;
  border-radius: 2px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

#order-now-dropdown .btn-primary {
  background-color: rgba(16, 70, 70, 1);
  border-color: rgba(16, 70, 70, 1);
}

#order-now-dropdown a {
  color: rgba(16, 70, 70, 1);
  background-color: white;
}

#order-now-button,
#order-now-button:hover {
  background-color: rgba(16, 70, 70, 1);
  white-space: nowrap;
  border-color: rgba(16, 70, 70, 1);
  font-family: "Gotham-Medium";
  font-size: 0.9em;
  letter-spacing: 0.19em;
  border-radius: 2px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

/* Hamburger Menu */
/* .navbar-light .navbar-toggler-icon {
  background-image: url("/images/hamburger_header.svg");
} */
.navbar-light .navbar-toggler {
  color: transparent;
  border-color: transparent;
  margin-bottom: 39px;
}
.navbar-toggler {
  border: none;
  padding: 0;
}

/* Responsive */
@media only screen and (max-width: 512px) {
  .navbar-light .navbar-toggler {
    margin-bottom: 6%;
  }
  #navbar-logo-ref {
    width: 50%;
  }
  #navbar-logo-img {
    width: 100%;
  }
}

@media only screen and (max-width: 1212px) {
  .leaf-link-container {
    margin-right: 0;
  }
}
