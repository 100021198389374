#storeinfo-green-box {
  display: inline-block;
  width: 100%;
  background-color: rgba(225, 248, 228, 1);
}

#storeinfo-text-container {
  margin-top: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
  color: rgba(16, 70, 70, 1);
}

#storeinfo-text-section-container {
  display: flex;
  margin-top: 6%;
}

@media only screen and (max-width: 768px) {
  /* Design for mobile */
  #storeinfo-green-box {
    width: 100%;
  }
  #storeinfo-text-section-container {
    flex-direction: column;
  }
}
