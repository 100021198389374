#footer-custom {
  background-color: rgba(16, 70, 70, 1);
  bottom: 0px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  color: white;
}

#footer-content {
  margin-top: 62px;
  margin-bottom: 78px;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  align-items: flex-start;
}

/* Tea Addicts logo */
#footer-tea-addicts-logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height: 5%; */
}

#footer-tea-addicts-logo-container img {
  padding: 2px;
}

/* Info Container */
.footer-info-container {
  margin-left: 10%;
  margin-right: 30%;
}

/* Social Media container */
#footer-social-media-container {
  display: flex;
}

#footer-social-media-container img {
  width: 50px;
  height: 50px;
  margin: 9px;
}

/* Generic classes to use */
.footer-horizontal {
  display: flex;
  align-items: center;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

/* Footer Line */
.footer-line-image {
  margin-right: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 1073px) {
  .footer-info-container {
    margin-right: 15%;
  }
}

@media only screen and (max-width: 768px) {
  /* Design for mobile */
  #footer-custom {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  #footer-content {
    font-size: 14px;
    margin-left: 5%;
  }
  #footer-tea-addicts-logo-container {
    display: none;
  }
  #footer-social-media-container {
    flex-direction: column;
    margin-top: -8px;
  }
  .footer-info-container {
    margin-right: 10%;
  }
}
