#about-page {
  display: flex;
  flex-direction: column;
}

#about-image {
  margin-top: -39px;
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
}

#plants-text-container {
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 8%;
}

@media only screen and (max-width: 768px) {
  /* Design for mobile */
  #about-image {
    display: none;
  }
  #plants-text-container {
    margin-left: 0;
  }
}
