/* Featured Drink */
.home-featured-drink-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home-featured-drinks-element-container img {
  width: 234px;
}

.home-featured-drink-container h3 {
  font-family: "Gotham-Medium";
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.18em;
  color: rgba(16, 70, 70, 1);
  line-height: 110%;
}

/* Yellow Box */
#home-featured-drinks-yellow-box {
  display: flex;
  width: 100%;
  background-color: rgba(255, 247, 224, 1);
  margin-bottom: 5%;
}

/* Text */
#home-featured-drinks-text-container {
  margin-top: 4%;
  margin-left: 8%;
  margin-bottom: 4%;
}

/* Text Color and Fonts */
#home-featured-drinks-text-container h1 {
  color: rgba(16, 70, 70, 1);
}

#home-featured-drinks-text-container h2 {
  font-family: "Gotham-Medium";
  font-size: 20px;
  letter-spacing: 0.2em;
  color: rgba(16, 70, 70, 1);
  line-height: 19px;
}

/* Try Them All Link */
#home-featured-drinks-text-container h2 {
  display: inline-block;
  margin-top: 10%;
  padding-bottom: 2%;
  border-bottom: 3px solid rgba(16, 70, 70, 1);
}

#home-featured-drinks-text-container a {
  text-decoration: none;
}

#home-featured-drinks-text-container h2:hover {
  color: gray;
  border-bottom: 3px solid gray;
}

/* Container for the Drinks */
#home-featured-drinks-element-container {
  display: flex;
  margin-top: -300px;
  margin-left: 45%;
  margin-bottom: 3%;
}

/* Mobile */
#home-featured-drinks-section-mobile {
  display: none;
}

#home-featured-drinks-yellow-box-mobile {
  display: flex;
  width: 100%;
  background-color: rgba(255, 247, 224, 1);
  margin-bottom: 5%;
  padding-top: 35px;
  padding-bottom: 48px;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #home-featured-drinks-section-normal {
    display: none;
  }
  #home-featured-drinks-section-mobile {
    display: flex;
  }
  .home-featured-drink-container img {
    width: 200px;
  }
}
