#plants-container {
  display: flex;
  align-items: flex-end;
  width: 35%;
  margin-bottom: 2%;
}

#plants-container img {
  height: auto;
}

@media only screen and (max-width: 768px) {
  /* Design for mobile */
  #plants-container {
    display: none;
  }
}
