/* Container */
#home-plants-section {
  display: flex;
  margin-left: 5%;
  margin-top: 4%;
  margin-bottom: 4%;
}

/* Green Box */
#home-plants-green-box {
  width: 60%;
  background-color: rgba(225, 248, 228, 1);
}

/* Text */
#home-plants-text-container {
  margin: 7%;
  color: rgba(16, 70, 70, 1);
}

/* Plant Image */
#home-plants-image {
  width: 40%;
  margin-left: -7%;
  margin-right: 5%;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  /* Design for tablet */
  #home-plants-section {
    flex-direction: column;
  }

  #home-plants-green-box {
    width: 94%;
  }

  #home-plants-image {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    width: 70%;
  }
}
