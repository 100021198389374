#storeinfo-container {
  display: flex;
  flex-direction: column;
}

#storeinfo-image {
  margin-top: -39px;
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
  z-index: -1;
}

#info-location-container {
  display: flex;
  margin-bottom: 4%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: -4%;
}

#storeinfo-map {
  width: 30%;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #storeinfo-image {
    display: none;
  }
  #info-location-container {
    margin-top: 4%;
    flex-direction: column;
  }
  #info-location-container iframe {
    height: 324px;
  }
  #storeinfo-map {
    width: 100%;
  }
}

/* Responsive (Mobile) */
@media only screen and (max-width: 512px) {
  #storeinfo-container h1 {
    font-size: 36px;
  }
  #storeinfo-container h2 {
    font-size: 22px;
  }
}
