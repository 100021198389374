#menu-container {
  display: flex;
}

#menu-carousel-and-menu {
  flex-direction: column;
  width: 74%;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #menu-container {
    flex-direction: column;
  }
  #menu-carousel-and-menu {
    width: 100%;
  }
}
