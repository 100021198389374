/* Yellow Box */
#contact-us-yellow-box {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: -39px;
  width: 94%;
  background-color: rgba(255, 247, 224, 1);
  margin-bottom: 64px;
}

/* Elements */
#contact-us-elements-container {
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 81px;
  margin-bottom: 137px;
  color: rgba(16, 70, 70, 1);
}

#contact-us-elements-container h1 {
  margin: 0;
}

#contact-us-elements-container h2 {
  font-family: "Gotham-Medium";
  font-size: 22px;
  letter-spacing: 0.18em;
  line-height: 21px;
  margin-bottom: 16px;
}

#contact-us-elements-container a {
  color: rgba(16, 70, 70, 1);
  text-decoration: none;
}

/* Subtitle */
#contact-us-subtitle-container {
  margin-top: 24px;
  margin-bottom: 71px;
}

/* Specific Lines */
.contact-us-info {
  margin-bottom: 56px;
}

/* Submit Form Button */
#contact-us-submit-form {
  display: inline-block;
  padding-bottom: 12px;
  border-bottom: 3px solid rgba(16, 70, 70, 1);
}

#contact-us-submit-form:hover {
  color: grey;
  border-bottom: 3px solid grey;
}

/* Form */
.form-label {
  font-family: "Gotham-Light";
  color: rgba(16, 70, 70, 1);
}

.form-control {
  border-color: rgba(180, 211, 183, 1);
  border-width: 2px;
  border-radius: 2px;
}

.form-control-line {
  width: 584px;
}

.form-control-multiline {
  width: 902px;
  margin-bottom: 40px;
}

/* Responsive (Mobile) */
@media only screen and (max-width: 512px) {
  #contact-us-yellow-box {
    margin-top: 4%;
  }
  #contact-us-elements-container {
    align-items: center;
    width: 80%;
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
  }
  #contact-us-elements-container h1 {
    font-size: 32px;
  }
  #contact-us-elements-container h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  #contact-us-elements-container p {
    font-size: 14px;
  }
}
