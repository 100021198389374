/* Container */
#menu-sidebar-container {
  display: flex;
  flex-direction: column;
  font-family: "Gotham-Medium";
  color: rgba(16, 70, 70, 1);
  margin-top: 100px;
  margin-left: 5%;
  margin-right: 6%;
  height: 500px;
  width: 15%;

  position: sticky;
  top: 100px;
}

#menu-sidebar-container h2 {
  font-size: 1.2em;
  letter-spacing: 0.19em;
  margin-bottom: 27px;
}

/* Squiggle */
#right-squiggle {
  width: 90%;
  margin-bottom: 27px;
}

/* Sections */
#menu-sidebar-container h3 {
  font-family: "Gotham-Medium";
  font-size: 0.9em;
  letter-spacing: 0.2em;
  margin-bottom: 0;
}

#menu-sidebar-container a {
  text-decoration: none;
  color: rgba(16, 70, 70, 1);
}

.menu-sidebar-section-container {
  display: flex;
  align-items: center;
  margin-left: -12px;
  margin-bottom: 12px;
}

/* Green Line */
.menu-sidebar-green-line {
  border-left: 3px solid rgba(162, 212, 169, 1);
  height: 12px;
  margin-right: 9px;
  visibility: hidden;
}

#menu-lines {
  margin-top: 40px;
}

#menu-lines h3 {
  margin-bottom: 12px;
}

.menu-line-container {
  display: flex;
  align-items: "center";
}
