#storeinfo-text-section {
  margin-right: 11%;
}

#storeinfo-text-section h2 {
  font-family: "Gotham-Medium";
  letter-spacing: 0.18em;
  font-size: 22px;
  line-height: 21px;
}

@media only screen and (max-width: 768px) {
  #storeinfo-text-section {
    margin-bottom: 48px;
  }
}
