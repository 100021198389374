#menu-item-container {
  display: flex;
  flex-direction: column;
  display: inline-block;
  text-align: center;
}

#menu-item-container h3 {
  font-family: "Gotham-Medium";
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.18em;
  color: rgba(16, 70, 70, 1);
  text-transform: uppercase;
  line-height: 110%;

  margin-top: 24px;
  margin-bottom: 2px;
}

.menu-item-image {
  width: 80%;
  height: auto;
}
