#home-page {
  display: flex;
  flex-direction: column;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  #home-page h1 {
    font-size: 32px;
  }
  #home-page h2 {
    font-size: 14px;
  }
}
