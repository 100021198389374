.menu-section-container {
  margin-top: 30px;
  margin-left: 5%;
  margin-right: 20%;
  width: 90%;
  scroll-margin-top: 30px;
}

.menu-section-container h1 {
  color: rgba(16, 70, 70, 1);
}

.menu-section-subtitle {
  width: 80%;
  margin-bottom: 51px;
}

/* Responsive (Tablet) */
@media only screen and (max-width: 768px) {
  .menu-section-container {
    scroll-margin-top: 100px;
  }
  .menu-section-subtitle {
    width: 100%;
  }
}
